import React from 'react'
import styled from 'styled-components'

import { media } from '../../../shared/utils/style-utils'

import {
  Layout,
  Jumbotron,
  TrendingPosts,
  PopularPosts,
  OtherPosts,
  EmailBanner,
} from '../components'
import { Divider } from '../../../shared'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium`
    max-width: 1367px;
  `}
`

const EmailBannerContainer = styled.div`
  ${media.large`
    margin-top: 65px;
  `}
`

export const IndexPage = () => {
  return (
    <Layout>
      <Container>
        <Jumbotron />
        <TrendingPosts />
        <Divider />
        <PopularPosts />
        <OtherPosts />
        <EmailBannerContainer>
          <EmailBanner />
        </EmailBannerContainer>
      </Container>
    </Layout>
  )
}

export default IndexPage
